body {
  font-family: 'Poppins', sans-serif !important;
  background-color: #0A0A0A !important;
  color: #fff !important;
}
/* header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1a1a1a !important;
  color: white;
} */

.alerts {
  background-color: #333;
  padding: 10px;
  border-radius: 5px;
}

.rContainer {
  display: none;
  height: 4vh;
  min-height: 45px;
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
  margin-bottom: 15px;
  margin-left: 15px;
}

/* Media query for small mobile screens */
@media only screen and (max-width: 600px) {
  .rContainer {
    display: flex;
    align-items: center;
    height: 4vh;
    min-height: 45px;
    padding: 10px;
    box-sizing: border-box;
    overflow: hidden;
    margin-left: 15px;
    margin-top: 15px;
  }
}

.dl {
  width: 250px; 
  border-radius: 15px; 
  background-color: #3872a1;
  border: none;
  padding: 10px;
  color: white;
}

.navbar {
  background: rgb(10, 10, 10) !important;
  border-bottom: 1px solid #222 !important;
}

.mr-auto {
  margin-right: auto !important;
}

.statistics {
  display: flex; 
  justify-content: space-around;
  /* margin-top: 20px;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; */
}

.card {
  background-color: #0A0A0A !important;
  border-radius: 20px !important;
  padding: 20px !important;
  /* margin-right: 15px !important; */
  color: white;
  border: 1px solid #222 !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.233);
  padding-top: 35px !important;
}

.smcard {
  background-color: #0f0f0f !important;
  border-radius: 15px !important;
  padding: 10px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  padding-top: 25px !important;
  /* margin-right: 15px !important; */
  color: white;
  border: 1px solid #222 !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.233);
}

.keyinput {
  border-radius: 10px;
  padding: 15px;
  margin: 0 auto !important;
  text-align:center;
  width: 300px;
  border: 1px solid #222;
  background-color: #111111 !important;
  color: white;
}

.modal-dialog {
  margin-top: 150px !important;
}

.modal-content {
  background-color: #141414 !important;
  color: white !important;
}

.modal-header {
  border-bottom: 1px solid #222 !important;
}

.modal-body {
  border-bottom: none !important;
  margin: 0 auto;
}

.modal-footer {
  border-top: none !important;
}

.big-number {
  font-size: 3em;
  font-weight: bold;
  margin-top: 10px;
  color: #fff;
}

.login-container {
  margin: 0 auto;
  max-width: 400px;
  text-align: center;
  width: 100%;
  margin-top: 100px;
}

.sidebar {
  width: 20px;
}

.reg-container {
  margin: 0 auto;
  max-width: 400px;
  text-align: center;
  width: 100%;
  margin-top: 20px;
}

input {
  border-radius: 10px;
  padding: 15px;
  margin: 10px !important;
  width: 300px;
  border: 1px solid #222;
  background-color: #111111 !important;
  color: white;
}

.update-time {
  font-size: 0.8em;
  color: #aaa;
}

.graph .card {
  margin-top: 20px;
}

.full-width {
  width: 100%;
}

footer {
  margin-top: 40px;
  color: white;
  text-align: center;
}

.tablea {
  background-color: #0A0A0A !important;
  color: white;
  border: 1px solid #222 !important;
  border-radius: 20px !important;
  margin: 0 auto;
  padding: 20px;
}

.tablea th {
  padding: 20px !important;
  background-color: #0f0f0f !important;
  color: #FFF !important
}

.tablea tr {
  border-bottom: 1px solid #222 !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-size: 15px !important;
}

.tablea:hover tr {
  background-color: #222 !important;
}

.tablea td {
  background-color: #0A0A0A !important;
  color: #cfcfcf !important;
}

.menu:hover {
  background-color: #111; /* Your desired hover background color */
}

/* For Webkit browsers like Chrome, Safari */
::-webkit-scrollbar {
  width: 6px !important; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #0c0c0c; /* Color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #76c893; /* Light green color for the scroll thumb */
  border-radius: 6px; /* Rounded corners for the scroll thumb */
  border: 5px solid #0f0f0f; /* Creates a border around the scroll thumb with the same color as the track */
}

.dot {
  color: #ffffff;
  animation: blink 1s infinite;
  margin-left: 15px;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* For Firefox */
* {
  /* scrollbar-width: thin; "auto" or "thin" */
  scrollbar-width: 6px;
  scrollbar-color: #3872a1 #0f0f0f; /* Scroll thumb color and track color */
}

/* For Internet Explorer and Edge */
body {
  -ms-overflow-style: -ms-autohiding-scrollbar; /* Optional: this hides scrollbars when they're not being used */
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagi {
  border-radius: 10px;
  background: #131313; 
  border: 1px solid #777;
  padding: 5px;
  min-width: 35px;
  margin: 5px;
  font-size: 14px;
  color: white;
  text-decoration: none;

  user-select: none;
}

.pagi:hover {
  background-color: #222;
}

.pagi--active {
  background-color: #333;
}

.pagi--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.sm-container {
  margin: 0 auto;
  max-width: 720px;
  width: 100%;
}

.md-container {
  margin: 0 auto !important;
  text-align:center;
  max-width: 960px;
  width: 100%;
}

.lg-container {
  margin: 0 auto !important;
  text-align:center;
  max-width: 1160px;
  width: 100%;
}

.xlg-container {
  margin: 0 auto !important;
  text-align:center;
  max-width: 1360px;
  width: 100%;
}


.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.nav-dropdown-dark {
  /* background-color: #050505 !important; */
  /* border-color: #111; */
}

.nav-dropdown-dark .dropdown-item {
  color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  /* background-color: #050505 !important; */
}

.nav-dropdown-dark .dropdown-item:hover, .nav-dropdown-dark .dropdown-item:focus {
  color: #eee;
  background-color: #222;
}

.nav-tabs .nav-link.active {
  color: #fff;
  background-color: #222 !important;
  border-color: #222;
  border: none !important;
}

.nav-tabs .nav-link {
  color: #fff !important;
  margin: 25px !important;
  border-radius: 15px !important;
  border: none !important;
  padding: 12px 0px !important;
  width: 120px;
}

.dropdown-menu {
  background-color: #000 !important;
  border-color: #3872a1;
  /* border: 1px solid #3872a1 !important; */
  border-radius: 10px !important;
  padding: 20px;
  min-width: 200px !important;
  text-align: center !important;
}

.console {
  border-radius: 10px;
  background: #131313; 
  border: 1px solid #777;
  padding: 5px;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
  margin-left: 45px;
}

.aiMessage {
  font-size: 15px !important;
}

.userMessage {
  font-size: 15px !important;
  text-align: right;
}

.keybtn {
  border-radius: 10px;
  background: #457c59; 
  border: none;
  padding: 10px;
  font-size: 14px;
  padding-left: 25px;
  padding-right: 25px;
  color: white;
}

.keybtnsm {
  border-radius: 10px;
  background: #457c59; 
  border: none;
  padding: 5px;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
}

.keybtnsmr {
  border-radius: 10px;
  background: #b92d2d; 
  border: none;
  padding: 5px;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
}

input[type=checkbox] {
  height: 0 !important;
  width: 0 !important;
  visibility: hidden !important;
}

label {
  cursor: pointer;
  text-indent: -9999px !important;
  width: 60px !important;
  height: 30px !important;
  background: grey !important;
  display: inline-block !important;
  border-radius: 70px  !important;
  position: relative !important;
}

label:after {
  content: "" !important;
  position: absolute !important;
  top: 0px !important;
  left: 0px !important;
  width: 30px !important;
  height: 30px !important;
  background: #bbbbbb !important;
  border-radius: 30px !important;
  transition: 0.3s !important;
}

input:checked + label {
  background: #0c80b6 !important;
}

input:checked + label:after {
  left: calc(100% - 0px) !important;
  transform: translateX(-100%) !important;
}

label:active:after {
  width: 60px !important;
}